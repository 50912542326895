import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import * as Images from "../../utilities/images";

const ForgotPassword = () => {
  document.title = "Forgot Password";
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const { loading } = authData;
  const [email, setEmail] = useState("");

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      showToast("Please enter your email");
      return;
    } else if (
      email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9_\-]+\.)+[a-zA-Z_]{2,}))$/.test(
        email
      )
    ) {
      showToast("Please enter valid email address");
      return;
    }
    let params = {
      email: email.trim(),
    };
    dispatch(
      forgotPassword({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/")
            // showToast("Please check your email");
            // navigate("/enter-otp", {
            //   state: {
            //     email: email,
            //   },
            // });
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="auth-bg-all">
      
           
              <div className="auth-outer-fields">
                <div className="text-center mb-2">
                  <img
                    className="adminLogo "
                    alt="company-logo"
                    src={Images.logoImg}
                  />
                </div>
                <div className="form-outer">
                  <div className="auth-heading-form">
                    <h1 className="primaryHeading mb-0 text-center">Forgot password</h1>
                    <p className="subHeading">
                      Enter the email address that associated with your account
                      for Verify.
                    </p>
                  </div>
                  <div className="formContainer">
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="input-container text-filed-outer">
                   <div className="form-group">
                   <label className="labelTxt">
                      Email
                    </label>
                      <input
                        type="text"
                        className="input-fileds"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <img
                        className="input-images"
                        src={Images.Message}
                        alt="lock"
                      />
                      </div>
                    </div>
                    <div className="my-3 text-end">
                      <Link to="/" className="forgot-heading">
                        Login
                      </Link>
                    </div>
                    <div className="text-center">
                      <button
                        disabled={loading}
                        className="auth-submit-button"
                        type="submit"
                      >
                        Continue &nbsp;&nbsp;
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                      </button>
                    </div>
                  </form>
                  </div>
                </div>
              </div>
            </div>
        
       
    </>
  );
};

export default ForgotPassword;
