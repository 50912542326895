import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  onErrorStopLoad,
  setgetDashBoardData,
  setgetAllUsers,
  setAddUsers,
  setUpdateUsers,
  setUpdateUserStatus,
  setGetUserInfo,
} from "../../slices/web";

function* updateUserStatus(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.UPDATE_USER_STATUS}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpdateUserStatus(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.dismiss();
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* updateUsers(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.UPDATE_USER}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpdateUsers(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* addUsers(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.ADD_USER}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setAddUsers(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getDashBoardData(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.DASHBOARD_DATA}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setgetDashBoardData(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getAllUsers(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_ALL_USER}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setgetAllUsers(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getUserInfo(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_USER_INFO}=${action.payload.user_id}`)
    );
    if (resp.status) {
      yield put(setGetUserInfo(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* webSaga() {
  yield all([takeLatest("web/getDashBoardData", getDashBoardData)]);
  yield all([takeLatest("web/getAllUsers", getAllUsers)]);
  yield all([takeLatest("web/addUsers", addUsers)]);
  yield all([takeLatest("web/updateUsers", updateUsers)]);
  yield all([takeLatest("web/updateUserStatus", updateUserStatus)]);
  yield all([takeLatest("web/getUserInfo", getUserInfo)]);

}

export default webSaga;
