import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  addUsers,
  updateUsers,
  onErrorStopLoad,
  getUserInfo,
} from "../../redux/slices/web";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useWebSelector } from "../../redux/selector/web";
import { blockInvalidChar } from "../../utilities/helper";
import * as Images from "../../utilities/images";

const ViewUserInfo = ({
  userId,
}) => {
  console.log(userId,"userId");
  
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const webData = useWebSelector();
  const { loading } = webData;

  const [getUserData, setGetUserData] = useState("");

console.log(getUserData,"getUserData");

  //stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // add users
  const handleUsersInfo = () => {
    let params = {
      user_id: userId,
    };
    dispatch(
      getUserInfo({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setGetUserData(res?.data)
          }
        },
      })
    );
  };
useEffect(()=>{
  handleUsersInfo()
},[])
  return (
    <>
      <div className="create-user-outer">
        <div className="mb-2 text-filed-outer">
          <label className="modal-labels">Full Name</label>
        </div>
      </div>
    </>
  );
};

export default ViewUserInfo;
