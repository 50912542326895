import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomModal from "../Modal/CustomModal";
import CreateSrTech from "../Modal/viewUserInfo";
import { Link } from "react-router-dom";
import { getAllUsers, updateUserStatus } from "../../redux/slices/web";
import { useDispatch } from "react-redux";
import { useWebSelector } from "../../redux/selector/web";
import * as Images from "../../utilities/images";
import ViewUserInfo from "../Modal/viewUserInfo";

const Users = () => {
  document.title = "Senior Technician";
  const dispatch = useDispatch();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const [allUsers, setAllusers] = useState([]);
  console.log(allUsers, "allUsers");

  const [user, setUser] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    userId:""
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      userId:""
    });
    setKey(Math.random());
  };

  //open modal
  const handleOpenModal = (flag, data) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
      userId:data
    });
    setKey(Math.random());
    setUser(data || "");
  };

  // Function to format date as DD/MM/YYYY
  const formatDate = (dob) => {
    const date = new Date(dob);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // update user Status
  const handleUpdateUserStatus = (id, status) => {
    if (status === "0") {
      handleOpenModal("veiwUser", id);
    } else {
      let params = {
        user_id: id,
        status: status,
      };
      dispatch(
        updateUserStatus({
          ...params,
          cb(res) {
            if (res?.status === 200) {
              handleGetAllUser();
            }
          },
        })
      );
    }
  };

  // table columns
  const columns = [
    {
      name: "User Profile",
      selector: (row) => (
        <img
          src={row.profile_pic || Images?.DummyImage}
          alt="User Profile"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
      reorder: true,
    },
    ,
    {
      name: "Full Name",
      selector: (row) => row.name || "-----",
      sortable: true,
      reorder: true,
    },
    {
      name: "Date Of Birth",
      selector: (row) => (row.dob ? formatDate(row.dob) : "-----"),
      sortable: true,
      reorder: true,
    },
    {
      name: "Ph No.",
      selector: (row) => row?.phone_number,

      // .userInfo.dialCode && row.userInfo.mobile
      //     ? `+${row.userInfo.dialCode} ${row.userInfo.mobile}`
      //     : "---",
      sortable: true,
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2 ">
          <div
            className={
              row?.is_admin_verified === 2
                ? "inActive-user active-user"
                : "active-user"
            }
          ></div>
          <p
            className={
              row?.is_admin_verified === 1
                ? "active-text mb-0 text-capitalize"
                : " in-active-text text-capitalize mb-0"
            }
          >
            {row?.is_admin_verified === 0
              ? "Pending"
              : row?.is_admin_verified === 1
              ? "Accept"
              : row?.is_admin_verified === 2
              ? "Reject"
              : ""}
          </p>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="dropdown filterDropdownChurchList">
          <button
            className="toggle-btn"
            type="button"
            disabled={row?.is_admin_verified === 2}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </button>
          <ul className="dropdown-menu">
            {/* <li onClick={() => handleOpenModal("createUser", row)}>
              <Link className="dropdown-item font-14 userText" to="#">
               Accept
              </Link>
            </li> */}
            {row?.is_admin_verified === 0 ? (
              <>
                <li onClick={() => handleUpdateUserStatus(row?.id, "0")}>
                  <Link className="dropdown-item font-14 userText" to="#">
                    View
                  </Link>
                </li>
                <li onClick={() => handleUpdateUserStatus(row?.id, "1")}>
                  <Link className="dropdown-item font-14 userText" to="#">
                    Accept
                  </Link>
                </li>
                <li onClick={() => handleUpdateUserStatus(row?.id, "2")}>
                  <Link className="dropdown-item font-14 userText" to="#">
                    Reject
                  </Link>
                </li>
              </>
            ) : row?.is_admin_verified === 1 ? (
              <>
                <li onClick={() => handleUpdateUserStatus(row?.id, "0")}>
                  <Link className="dropdown-item font-14 userText" to="#">
                    View
                  </Link>
                </li>
                <li onClick={() => handleUpdateUserStatus(row?.id, "2")}>
                  <Link className="dropdown-item font-14 userText" to="#">
                    Reject
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      ),
    },
  ];

  // get all users
  useEffect(() => {
    handleGetAllUser();
  }, []);

  // get all users
  const handleGetAllUser = () => {
    dispatch(
      getAllUsers({
        cb(res) {
          if (res?.status === 200) {
            setAllusers(res?.data?.data?.users);
          }
        },
      })
    );
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex align-items-center">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Users</h1>
              </div>
              {/* <div className="col-sm-6">
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => handleOpenModal("createUser")}
                    className="common-btn"
                  >
                    <i className="fa fa-user-plus me-2"></i>
                    Sr Technician
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <section className="content commonBox_  admindash">
          <div className="data-table">
            <DataTable
              columns={columns}
              data={allUsers}
              pagination
              responsive={true}
              progressComponent={
                <div className="spinner-grow " role="status"></div>
              }
              highlightOnHover={true}
              progressPending={loading && !modalDetail.show}
            />
          </div>
        </section>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "congratulation"
            ? "commonWidth  customContent"
            : ""
        }
        ids={modalDetail.flag === "veiwUser" ? "createPassword" : ""}
        child={
          modalDetail.flag === "veiwUser" ? (
            <ViewUserInfo
              userId={modalDetail?.userId}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "veiwUser" ? (
            <>
              <h2 className="profile_modal_heading">User Info</h2>
              <p
                onClick={() => handleOnCloseModal()}
                // className="modal_cancel"
              >
                <i class="fa fa-xmark"></i>
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Users;
