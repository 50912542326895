import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllUsers, getDashBoardData, onErrorStopLoad } from "../../redux/slices/web";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate()
  document.title = "Dashboard";
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState([]);


  // get all users
  const handleGetAllUser = () => {
    dispatch(
      getAllUsers({
        cb(res) {
          if (res?.status === 200) {
            setDashboardData(res?.data?.data?.users);
          }
        },
      })
    );
  };
   // get all users
   useEffect(() => {
    handleGetAllUser();
  }, []);

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <section className="content commonBox_  admindash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box dashboxcolor" onClick={() => {
                    navigate("/user")
                  }}>
                <div className="inner">
                  <h3>{dashboardData?.length}</h3>
                  <p>All User</p>
                </div>
                <div className="icon">
                  {/* <i className="ion ion-person-add" /> */}
                  <i className="fa fa-users" />
                </div>
                <a className="small-box">
                    <i className="fas " />
                  </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/sr-technicions">
                <div className="small-box dashboxcolor_two">
                  <div className="inner">
                  <h3>0</h3>
                    <p>Event</p>
                  </div>
                  <div className="icon">
                    {/* <i className="fas fa-bread-slice"></i>{" "} */}
                    <i className="fas fa-crown"></i>
                  </div>
                  <a className="small-box">
                    <i className="fas " />
                  </a>
                </div>
              </Link>
            </div>

            {/* <div className="col-lg-3 col-6">
            <Link to="/jr-technicions">
              <div className="small-box bg-warning">
                <div className="inner">
                <h3>{dashboardData?.junior_technician}</h3>
                  <p>Demo</p>
                </div>
                <div className="icon">
                  <i className="fa fa-wrench" />
                </div>
                <a className="small-box">
                  <i className="fas" />
                </a>
              </div>
              </Link>
            </div> */}

            {/* <div className="col-lg-3 col-6">
              <div className="small-box bg-danger dashfour">
                <div className="inner">
                  <h3>45</h3>
                  <p>Voice Calls</p>
                </div>
                <div className="icon">
                  <i className="fa fa-phone" />
                </div>
                <a className="small-box">
                  <i className="fas " />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
