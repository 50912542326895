import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    updateUserStatus: (state) => {
      state.loading = true;
    },
    setUpdateUserStatus: (state) => {
      state.loading = false;
    },
    updateUsers: (state) => {
      state.loading = true;
    },
    setUpdateUsers: (state) => {
      state.loading = false;
    },
    addUsers: (state) => {
      state.loading = true;
    },
    setAddUsers: (state) => {
      state.loading = false;
    },
    getDashBoardData: (state) => {
      state.loading = true;
    },
    setgetDashBoardData: (state) => {
      state.loading = false;
    },
    getAllUsers: (state) => {
      state.loading = true;
    },
    setgetAllUsers: (state) => {
      state.loading = false;
    },
    getUserInfo: (state) => {
      state.loading = true;
    },
    setGetUserInfo: (state) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onErrorStopLoad,
  getDashBoardData,
  setgetDashBoardData,
  getAllUsers,
  setgetAllUsers,
  addUsers,
  setAddUsers,
  updateUsers,
  setUpdateUsers,
  updateUserStatus,
  getUserInfo,
  setGetUserInfo,
  setUpdateUserStatus
} = webSlice.actions;

export default webSlice.reducer;
