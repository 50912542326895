import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { adminLogin, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";
import * as Images from "../../utilities/images";
import { toast } from "react-toastify";

const Login = () => {
  document.title = "Login";
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const { loading } = authData;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      showToast("Please enter your email");
      return;
    } else if (
      email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      showToast("Please enter valid email address");
      return;
    } else if (!password) {
      showToast("Please enter your password");
      return;
    }
    let params = {
      email: email.trim(),
      password: password,
    };
    dispatch(
      adminLogin({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/user");
          }
        },
      })
    );
  };

  // toggle password confirmation
  const handleTogglePassword = () => {
    setTogglePassword(!togglePassword);
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="auth-bg-all">




        <div className="auth-outer-fields">
          <div className="text-center mb-2">
            <img
              className="adminLogo"
              alt="company-logo"
              src={Images.logoImg}
            />
          </div>
          <div className="form-outer">
            <div className="heading-form">
              <h1 className="primaryHeading mb-0 text-center">Login</h1>
            </div>
            <div className="formContainer">


              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="input-container text-filed-outer emailInput">
                  <div className="form-group">
                    <label className="labelTxt">
                      Email
                    </label>
                    <input
                      type="text"
                      className="input-fileds"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <img
                      className="input-images"
                      src={Images.Message}
                      alt="lock"
                    />
                  </div>

                </div>
                <div className="input-container text-filed-outer ">
                  <div className="form-group">
                    <label className="labelTxt">
                      Password
                    </label>
                    <input
                      type={togglePassword ? "text" : "password"}
                      className="input-fileds"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <img
                      onClick={handleTogglePassword}
                      className="input-images-password"
                      src={
                        togglePassword ? Images.showeye : Images.hidePassword
                      }
                      alt="eye-icon-lock"
                    />
                  </div>
                </div>

                <div className="remember-me-checkbox ">
                
                    {/* <div class="form-group mb-0">
                      <input type="checkbox" id="javascript" />
                      <label for="javascript">Keep me Logged in</label>
                    </div> */}
           

                  <Link to="/forgot-password" className="forgot-heading">
                    Forgot password?
                  </Link>
                </div>
                <div className="text-center">
                  <button
                    disabled={loading}
                    className="auth-submit-button"
                    type="submit"
                  >
                    Log In &nbsp;&nbsp;
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Login;
