import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  loading: false,
  userInfo: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetPassword: (state) => {
      state.loading = true;
    },
    setResetPassword: (state) => {
      state.loading = false;
    },
    forgotPassword: (state) => {
      state.loading = true;
    },
    setForgotPassword: (state, action) => {
      state.loading = false;
      state.adminEmail = action.payload;
    },
    adminLogin: (state) => {
      state.loading = true;
    },
    setAdminLogin: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
      state.isLoggedIn = true;
    },
    adminLogout: (state) => {
      state.loading = true;
    },
    setAdminLogout: (state) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.userInfo = null;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  forgotPassword,
  setForgotPassword,
  adminLogin,
  setAdminLogin,
  onErrorStopLoad,
  adminLogout,
  setAdminLogout,
  resetPassword,
  setResetPassword,
} = authSlice.actions;

export default authSlice.reducer;
